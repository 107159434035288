/* --- Typography ---*/
@font-face {
	font-family: 'adventure';
	src: url('../fonts/adventure_hollow.woff2') format('woff2'),
			 url('../fonts/adventure_hollow.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'talldeco';
	src: url('../fonts/talldeco.woff2') format('woff2'),
			 url('../fonts/talldeco.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

html {
	@include fluid-type($min_width, $max_width, $min_font, $max_font); // scss-lint:disable NameFormat
}

body {
	font-family: $bodyFont;
	line-height: 1.5;
	color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $headingFont;
	margin: 0;
	padding-bottom: 1em;
}

