/* --- Helper classes --- */
.visuallyhidden {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px); // scss-lint:disable DuplicateProperty
	white-space: nowrap; /* added line */
}

.mobile {
	display: none;

	@include respond-above('md') {
		display: none;
	}
}
