/* --- Layout and Positioning --- */

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
	box-sizing: border-box;
}

body {
	font-family: 'Verdana';
	font-size: 1.8rem;
}

img {
	max-width: 100%;
}

.headings {
	display: flex;
	justify-content: center;
	align-items: center;
}

footer {
	align-self: end;
}