/* --- The Grid --- */
.container {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: 1em;
	grid-row-gap: 1em;
	width: 96%;
	height: 100%;
	min-height: 100vh;
	padding: 0 2%;

	@include respond-above('md') {
		width: 80%;
		max-width: 100em;
		margin: 0 auto;
		padding: 0 5%;
	}
}

.header {
	grid-column: span 9;
}

.identity {
	grid-column: span 2;
}

.menu {
	grid-column: span 1;
}

.page {
	grid-column: span 12;

	@include respond-above('md') {
		grid-column: span 4;
	}
}

.sidebar {
	grid-column: span 12;
}

.footer {
	grid-column: span 12;
}
