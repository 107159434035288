/* --- Identity --- */

.identity {
	align-self: self-start;
	background-color: $white;
	border: 4px solid $black;
	border-top: 0;

	&__issue {
		display: flex;
		justify-content: space-around;
		border-top: 4px solid $black;
	}

	&__images {
		display: flex;
		flex-direction: column;

		.compact-logo {
			padding: 0 1em;
		}
	}
}

.issue {
	padding: 0 1rem;
	border-right: 4px solid $black;

	span {
		display: block;
		text-align: center;

		&:first-child {
			font-size: 30px;
			font-weight: bold;
			line-height: 1;
			text-transform: uppercase;
		}
	}
}

.author-link {
	max-width: 5rem;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
}

.menu {
	&__button {
		display: block;

		.material-icons {
			font-size: 6rem;
			color: $black;
		}
	}
}
