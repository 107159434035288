$black: #010101;
$white: #ffffff;

$displayFont: 'octantregular', 'Josefin Sans', 'Georgia', serif;
$headingFont: "Josefin Sans", sans-serif;
$bodyFont: "Libre Baskerville", serif;

// scss-lint:disable NameFormat

$min_width: 320px;
$max_width: 1200px;
$min_font: 17px;
$max_font: 20px;

$min_h_width: 320px;
$max_h_width: 1200px;
$min_h_font: 40px;
$max_h_font: 64px;
