/* --- Cover Page --- */

.logo {
	padding: 1em 0;
}

.header {
	&__titles {
		img {
			width: 100%;
		}
	}
}

.title {
	font-family: 'adventure';
	font-size: 72px;
	line-height: 1.1;
	padding-bottom: 1rem;
}

.tagline {
	font-family: 'talldeco';
	font-size: 40px;
	line-height: 1.1;
	text-align: center;
	padding-bottom: 1rem;
}
